import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import LocalizedLink from "../../../i18n/localized-link"
import { withTranslation } from "../../../i18n/translate"
import ArrowLeftIcon from "../../../images/icons/arrow_left.svg"
import ArrowRightIcon from "../../../images/icons/arrow_right.svg"
import { ProductModel } from "../../../model/productModel"
import { HorizontalScrollContainerHelper } from "../../../util/horizontal-scroll-container-helper"
import trackCustomEvent from "../../../util/trackCustomEvent"
import Info from "../../info/info"
import RichTextPreview from "../../rich-text/rich-text-preview"
import Tile from "../../tile/tile"
import CartButton from "../actions/cart-button/cart-button"
import ComparisonButton from "../actions/comparison-button/comparison-button"
import ProductParam from "../product-param"
import { filterProductParameterGroupsByProducts } from "../product-param-helpers"
import "./product-comparison.sass"

class ProductComparison extends React.Component {
  constructor(props) {
    super(props)

    this.onResize = this.onResize.bind(this)
    this.scrollToNextProduct = this.scrollToNextProduct.bind(this)
    this.scrollToPreviousProduct = this.scrollToPreviousProduct.bind(this)

    this.state = {
      isSSr: true,
      isFullWidth: false,
      leftButtonActive: false,
      rightButtonActive: true,
      heights: [],
    }

    this.productsToUse = this.props.products.map(
      product => new ProductModel(product),
    )
    this.filteredProductParamGroups = filterProductParameterGroupsByProducts(
      this.props.productParamGroups,
      this.productsToUse,
      this.props.t,
    ).filter(group => !group.hidden)

    this.groupRefs = []
    this.columnRefs = []

    for (
      let i = 0;
      i < this.productsToUse.length * this.filteredProductParamGroups.length;
      i++
    ) {
      this.groupRefs.push(React.createRef())
    }
    for (let i = 0; i < this.productsToUse.length; i++) {
      this.columnRefs.push(React.createRef())
    }

    this.scrollContainerRef = React.createRef()
  }

  componentDidMount() {
    // Exceptions //

    if (this.props.titleTranslationKey) {
      throw new Error('prop  "titleTranslationKey" is deprecated')
    }

    //
    this.productComparisonContainer = this.scrollContainerRef.current

    this.scrollContainerHelper = new HorizontalScrollContainerHelper(
      this.productComparisonContainer,
      state => this.setState(state),
    )

    /// Handle Resize ///
    setTimeout(() => {
      this.onResize()
    })
    this.resizeListener = () => this.onResize()
    window.addEventListener("resize", this.resizeListener)

    const heights = this.filteredProductParamGroups.map(() => 0)
    for (let i = 0; i < this.filteredProductParamGroups.length; i++) {
      for (let j = 0; j < this.productsToUse.length; j++) {
        const val = this.groupRefs[this.productsToUse.length * i + j].current
          .scrollHeight
        if (val > heights[i]) {
          heights[i] = val
        }
      }
    }
    this.setState({ heights, isSSr: false })
  }

  componentWillUnmount() {
    this.scrollContainerHelper.destroy()

    window.removeEventListener("resize", this.resizeListener)
  }

  onResize() {
    const productColumns = this.productComparisonContainer.children

    if (
      productColumns &&
      this.productComparisonContainer &&
      productColumns[0]
    ) {
      this.setState({
        isFullWidth:
          this.productComparisonContainer.clientWidth ===
          productColumns[0].clientWidth,
      })
    }
  }

  scrollToNextProduct() {
    this.scrollContainerHelper.scrollRight()
  }

  scrollToPreviousProduct() {
    this.scrollContainerHelper.scrollLeft()
  }

  render() {
    const { t } = this.props
    this.productsToUse = this.props.products.map(
      product => new ProductModel(product),
    )
    const height =
      Math.floor(
        this.productComparisonContainer?.children[0].getBoundingClientRect()
          .height,
      ) || "100%"

    const productComparison = (
      <div className="_fp-product-comparison">
        <div
          style={{ height }}
          className="_fp-product-comparison__property-names"
        >
          {this.filteredProductParamGroups.map((group, index) => {
            return (
              <div
                className="_fp-product-comparison__property-names__row"
                key={`product_comparison_row_${index}`}
                style={{ minHeight: this.state.heights[index] }}
              >
                {group.type === "EFFECT"
                  ? t("PRODUCTS-PRODUCT_FILTERS-EFFECT_COLON")
                  : ""}
                {group.name && t(group.name.key)}
                {group.info && (
                  <Info
                    customClassName={
                      "_fp-product-comparison__property-names__info"
                    }
                    label={t(group.info.key)}
                  />
                )}
              </div>
            )
          })}
          {/* Add empty row to bottom as a spacer*/}
          <div className="_fp-product-comparison__property-names__row _fp-product-comparison__property-names__row--empty"></div>
        </div>
        <div
          className="_fp-product-comparison__products"
          ref={this.scrollContainerRef}
        >
          {this.productsToUse.map((product, index) => {
            return (
              <div
                key={`product_column_${index}`}
                className={"_fp-product-comparison__products__column"}
              >
                <div
                  className="_fp-product-comparison__products__column__row _fp-product-comparison__products__column__row--large"
                  key={`product_comparison_${index}`}
                >
                  <div className="_fp-product-comparison__products__product-info">
                    <LocalizedLink
                      to={product.getUrl()}
                      state={{ filterKey: this.props.filterKey }}
                      onClick={() =>
                        trackCustomEvent({
                          category: "product_interaction",
                          action: "navigate_via_comparison",
                          label: product.name,
                        })
                      }
                    >
                      <h4 className="_fp-heading-4 _fp-product-comparison__products__product-info__title">
                        {product.name}
                      </h4>
                    </LocalizedLink>
                    {product.description && (
                      <p className="_fp-text _fp-product-comparison__products__product-info__description">
                        <RichTextPreview
                          textTranslatedString={t(product.description.key)}
                        ></RichTextPreview>
                      </p>
                    )}
                    <div className="_fp-product-comparison__products__product-info__actions">
                      <ComparisonButton product={product} />
                      <CartButton product={product} />
                    </div>
                  </div>
                </div>

                {this.filteredProductParamGroups.map((group, groupIndex) => {
                  return (
                    <div
                      className="_fp-product-comparison__products__column__row"
                      key={`product_comparison_column_item_${groupIndex}`}
                      style={{ minHeight: this.state.heights[groupIndex] }}
                    >
                      <p
                        className={
                          "_fp-product-comparison__products__column__row__parameter-name _fp-text _fp-text--small"
                        }
                      >
                        {group.name && t(group.name.key)}
                      </p>
                      <ProductParam
                        group={group}
                        param={product.getParamValues(group)}
                        type={group.type}
                        paramRef={
                          this.groupRefs[
                            this.productsToUse.length * groupIndex + index
                          ]
                        }
                        vertical={true}
                      />
                    </div>
                  )
                })}
                {/* Generate Last Row for spacing Purposes */}
                <div
                  className={classNames(
                    "_fp-product-comparison__products__column__row _fp-product-comparison__products__column__row--empty",
                  )}
                />
              </div>
            )
          })}
        </div>
      </div>
    )

    if (this.props.showTile) {
      return (
        <Tile
          iconButtons={[
            {
              iconComponent: () => <ArrowLeftIcon />,
              active: this.state.leftButtonActive,
              onClick: () => this.scrollToPreviousProduct(),
            },
            {
              iconComponent: () => <ArrowRightIcon />,
              active: this.state.rightButtonActive,
              onClick: () => this.scrollToNextProduct(),
            },
          ]}
          titleTranslatedString={this.props.titleTranslatedString}
        >
          {this.props.children}
          {productComparison}
        </Tile>
      )
    } else {
      return productComparison
    }
  }
}

ProductComparison.propTypes = {
  products: PropTypes.any,
  productParamGroups: PropTypes.any,
  titleTranslatedString: PropTypes.string,
  showTile: PropTypes.bool,
  filterKey: PropTypes.string,
}

export default withTranslation()(ProductComparison)
