import React from "react"
import { connect } from "react-redux"

import EffectsLegendHoc from "../components/effects-legend/effects-legend"
import InfoSection from "../components/info-section/info-section"
import PageHeader from "../components/page-header/page-header"
import ContactSalesBanner from "../components/product/contact-sales-banner/contact-sales-banner"
import ProductComparison from "../components/product/product-comparison/product-comparison"
import SEO from "../components/seo"
import Tile from "../components/tile/tile"
import useProductParameterGroups from "../hooks/useProductParameterGroups"
import useProducts from "../hooks/useProducts"
import { useTranslation } from "../i18n/translate"
import { ProductModel } from "../model/productModel"
import useIsClient from "../util/is-client"

const CompareProductsPage = ({ productsInComparison, location }) => {
  const { t } = useTranslation()
  const isClient = useIsClient()

  const productParamGroups = useProductParameterGroups().concat([
    {
      name: { key: "MARKETS-MARKET" },
      info: { key: "PRODUCTS-PRODUCT_FILTERS-MARKET-INFO" },
      strapiId: -2,
      prefix: null,
      suffix: null,
      type: "ENUM",
      order: 110,
    },
  ])

  const allProducts = productsInComparison.map(
    (product) => new ProductModel(product),
  )

  return (
    <>
      <SEO
        pathName={location.pathname}
        titleTranslatedString={t("PRODUCTS-COMPARE_PRODUCTS-META_TITLE")}
        descriptionTranslatedString={t(
          "PRODUCTS-COMPARE_PRODUCTS-META_DESCRIPTION",
        )}
      />
      <PageHeader
        levelTwoTitle={t("PRODUCTS-COMPARE_PRODUCTS-META_TITLE")}
        levelThreeTitle={t("PRODUCTS-COMPARE_PRODUCTS-META_TITLE")}
      />
      <div className={"_fp-global-container _fp-grid--margin-top"}>
        <div className="_fp-grid _fp-grid--gap">
          <div className="_fp-col-12">
            {allProducts.length > 0 && isClient ? (
              <EffectsLegendHoc
                products={allProducts}
                productParamGroups={productParamGroups}
              >
                <ProductComparison
                  titleTranslatedString={t(
                    "PRODUCTS-COMPARE_PRODUCTS-PRODUCT_COMPARISON",
                  )}
                  showTile={true}
                  products={allProducts}
                  productParamGroups={productParamGroups}
                />
              </EffectsLegendHoc>
            ) : (
              <Tile
                titleTranslatedString={t(
                  "PRODUCTS-COMPARE_PRODUCTS-PRODUCT_COMPARISON",
                )}
              >
                <InfoSection
                  textTranslatedString={t(
                    "PRODUCTS-COMPARE_PRODUCTS-EMPTY_PRODUCT_COMPARISON_INFO",
                  )}
                />
              </Tile>
            )}
          </div>
          {allProducts.length > 0 && isClient && (
            <div className="_fp-col-12">
              <ContactSalesBanner
                infoSectionStyle={"tile"}
                products={allProducts}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({ comparison }) => {
  return {
    productsInComparison: comparison,
  }
}

const withProducts =
  (WrappedComponent) =>
  ({ productsInComparison, ...props }) => {
    const products = useProducts()

    props.productsInComparison = products.filter(
      (product) =>
        productsInComparison.findIndex((c) => c.id === product.id) !== -1,
    )
    return <WrappedComponent {...props} />
  }

export default connect(mapStateToProps, {})(withProducts(CompareProductsPage))
