import { navigate } from "gatsby"
import React from "react"
import { connect } from "react-redux"

import localizeLink from "../../../i18n/localize-link"
import { useTranslation } from "../../../i18n/translate"
import ContactIcon from "../../../images/icons/contact.svg"
import Button from "../../button/button"
import InfoSection from "../../info-section/info-section"

const ContactSalesBanner = ({ products, addProducts, infoSectionStyle }) => {
  const { t, i18n } = useTranslation()
  const locale = i18n.language

  return (
    <InfoSection
      textTranslatedString={t("PRODUCTS-CONTACT_SALES-TEXT")}
      infoSectionStyle={infoSectionStyle}
      buttonComponent={
        <Button
          buttonSvgIconComponent={<ContactIcon />}
          buttonStyle="green"
          buttonCallback={() => {
            addProducts(products)
            navigate(localizeLink("/contact-sales/", locale))
          }}
          buttonLabel={t("PRODUCTS-CONTACT_SALES-CTA")}
        />
      }
    ></InfoSection>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    addProducts: (products) => {
      dispatch({ type: "SET_CONTACT_REQUEST", products })
    },
  }
}

export default connect(undefined, mapDispatchToProps)(ContactSalesBanner)
