import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "../../i18n/translate"
import InfoIcon from "../../images/icons/info.svg"
import "./info-section.sass"

const InfoSection = ({
  textTranslatedString,
  children,
  buttonComponent,
  infoSectionStyle,
  ...props
}) => {
  const { t } = useTranslation()

  /// Exceptions

  if (props.textTranslationKey) {
    throw new Error('prop "textTranslationKey" is deprecated')
  }

  //

  return (
    <div
      className={classNames("_fp-info-section _fp-print-hidden", {
        "_fp-info-section--tile-style": infoSectionStyle === "tile",
      })}
    >
      <div className="_fp-info-section__icon">
        <InfoIcon />
      </div>
      <div className="_fp-info-section__content">
        {textTranslatedString ? (
          <p className="_fp-text">{t(textTranslatedString)}</p>
        ) : (
          <div className="_fp-text">{children}</div>
        )}
        {buttonComponent && (
          <div className="_fp-info-section__button">{buttonComponent}</div>
        )}
      </div>
    </div>
  )
}

InfoSection.propTypes = {
  infoSectionStyle: PropTypes.oneOf(["default", "tile"]),
  textTranslatedString: PropTypes.string,
  buttonComponent: PropTypes.node,
}

InfoSection.defaultProps = {
  infoSectionStyle: "default",
}

export default InfoSection
